import { get } from 'lodash';
import moment from 'moment';
import styles from "assets/scss/components/PlayerOfTheWeek.module.scss";
import classNames from 'classnames'

export default function PlayerOfTheWeek({...props}) {
    const { playerOfTheWeeks } = props;
    const latestPlayersStory = get(playerOfTheWeeks, 'edges.0.node');

    if (latestPlayersStory) {
        return <div className={classNames(styles.sidebarWidget, styles.playerOfTheWeekWidget)}
                    data-cy={'playerOfTheWeekWidget'}>
            <div className={classNames(styles.lhmBanner, 'p-0 mt-0')}>
                <img src="/images/sponsor/lhm-logo.png"/>
            </div>
            <div className={styles.widgetTitle}>
                {latestPlayersStory.title}
            </div>
            <div className={styles.subText}>
                Published {moment(latestPlayersStory.publishedTime).format('MMMM D, YYYY')}
            </div>
            <div className={styles.widgetImage}>
                <img src={latestPlayersStory.imageUrl}/>
            </div>
            <div className={styles.widgetButton}>
                <a target="_blank" href={latestPlayersStory.url}>MEET THE ATHLETES</a>
            </div>
            <div>
                <div style={{
                    padding: '56.25% 0 0 0',
                    position: 'relative'
                }}>
                    <iframe
                        src="https://player.vimeo.com/video/930365344?h=56de37432a&autoplay=1&muted=1&title=0&byline=0"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%'
                        }}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                    ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </div>;
    }
    return null;
};
