/**
 * Gets a path with changed queries based on the current queries and path.
 * Will parse url variables such as: "/sport/[sport]" into something like "/sport/football" if given the "sport" in the query
 *
 * @param pathName The pathname we are using. If using next/router object, use router.pathname.
 * @param query The query to parse. Must specify the url variable if you need to replace it. For example, a path of "/sport/[sport]" must have {"sport": "my-sport-here"} supplied.
 * @param ignoreExtraQueryParams. Transforms the extra queries into query params.
 */
export function parseRouterPath(pathName, query, ignoreExtraQueryParams = true) {
    const newQuery = Object.assign({}, query); // Copy it over cause we will be mutating the query //
    // Will match on something like /sport/[sport]. So we can replace "[sport]" with something like "football" //
    // Has side effect: will remove query entry from query if it matches a path variable like "[sport]" //
    const filledPath = pathName.split('/').map(segment => {
        const matches = segment.match(/^\[(\.\.\.)?(.+)]$/);

        if (!matches || matches.length < 2) {
            return segment;
        }

        const urlQuery = matches[2];

        if (!newQuery.hasOwnProperty(urlQuery)) {
            return segment;
        }

        const value = newQuery[urlQuery];
        delete newQuery[urlQuery];

        return value;
    }).join('/')
    let newQueryString = Object.keys(newQuery).length > 0 && !ignoreExtraQueryParams ? '?' + Object.keys(newQuery).map(q => `${q}=${newQuery[q]}`).join('&') : '';
    return {
        path: pathName,
        as: filledPath + newQueryString
    };
}

/**
 * Appends additional route params if they don't already exist.
 *
 * For example:
 * - includePathOptions('/high-school', ['sport']) => '/high-school/[sport]'
 * - includePathOptions('/high-school/[sport]/scores-schedule', ['date', 'region']) => '/high-school/[sport]/scores-schedule/[date]/[region]'
 *
 * @param pathname
 * @param options
 * @returns {string}
 */
export const includePathOptions = (pathname, options) => {
    const allowedOptions = options.filter(opt => !pathname.includes(`[${opt}]`));
    return pathname + (allowedOptions.length > 0 ? '/' : '') + allowedOptions.map(opt => `[${opt}]`).join('/');
}
