import styles from "assets/scss/components/ScoreSubmission.module.scss";
import boxStyles from 'assets/scss/components/Box.module.scss';
import classNames from 'classnames'

export default function ScoreSubmissionsWidget() {

    return <div className={classNames(boxStyles.box, styles.sidebarWidget, styles.scoreSubmission)}>
        <img src="/images/svg/icon_submission_full.svg" />

        <div className={styles.widgetTitle}>
            Score Submissions
        </div>
        <div className={styles.subText}>
            To submit scores or corrections, please send us an email at:<br/>prepstats@deseretnews.com
        </div>
        <div className={styles.widgetButton}>
            <a href="mailto:prepstats@deseretnews.com">SUBMIT NOW</a>
        </div>
    </div>;
};
