import styles from "assets/scss/components/News.module.scss";
import { get, initial, last, filter, find } from "lodash";
import moment from "moment";
import DfpAd from "components/DfpAd";
import PlayerOfTheWeekWidget from 'components/PlayerOfTheWeekWidget';
import ScoreSubmissionWidget from 'components/ScoreSubmissionsWidget';
import { useState } from "react";

const makeByLine = authors => {

    const anchor = (author) => {
        if (!author) {
            return <></>;
        }

        // TODO: When this part of the API is available/not erroring out. Get the proper url for the author

        // const username = get(author, 'username');
        //
        // return username
        //     ? <a key={author.uid} href={`https://deseret.com/authors/${get(author, 'username')}`}>{get(author, 'fullName')}</a>
        //     : <span key={author.uid}>{get(author, 'fullName')}</span>

        return <span key={author.uid}>{get(author, 'fullName')}</span>
    }

    switch (authors.length) {
        case 0:
            return <span>By Deseret News Sports Team</span>
        case 1:
            return <span>By {anchor(authors[0])}</span>
        case 2:
            return <span>By {anchor(authors[0])} and {anchor(authors[1])}</span>
        default:
            const authorsComponents = [];
            for (let i = 0; i < authors.length; i++) {
                if (i === authors.length - 1) {
                    authorsComponents.push(', and ')
                } else if (i > 0) {
                    authorsComponents.push(', ');
                }

                const curAuthor = authors[i];
                authorsComponents.push(anchor(curAuthor));
            }
            return <span>
                By <>
                    {authorsComponents}
                </>
            </span>
    }
}

export default function NewsComponent({ title, selectedPage, onPageSelected, sport, school, loading, ...props }) {
    const {stories, heroes, total, pages, errors} = props.storiesAndHeroes;
    const mainHero = heroes?.[0];
    const heroSides = [heroes?.[1], heroes?.[2]].filter(hero => !!hero);
    const riverStories = filter(stories, story => ![get(heroes, '0.uid'), get(heroes, '1.uid'), get(heroes, '2.uid')].includes(story.uid));

    const [page, setPage] = useState(parseInt(selectedPage));

    const homePage = !school & !sport;

    const currentDate = moment();
    const archivesPage = `https://www.deseret.com/archives/sports/${currentDate.format('YYYY')}/${currentDate.format('M')}`;

    function pageSelected(page) {
        if (onPageSelected) {
            onPageSelected(page); // To tell our parent we got a new date
        }
    }
    if (stories.length === 0) {
        // Probably should do a loading thing or something //
        return <></>
    }

    if (errors) {
        return (
            <div className="container p-0 m-0">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <>
                            <h1>Stories are currently unavailable</h1>
                            <div className={styles.box}>
                                <div>
                                    {
                                        !school && !sport ? (
                                            <>
                                                <p>We're sorry for the inconvenience! In the meantime, you can still
                                                    explore:</p>
                                                <ul className={styles.underlined}>
                                                    <li>
                                                        <a href={"https://sports.deseret.com/high-school/scores-schedule"}>
                                                            Scores and Schedule
                                                        </a>
                                                    </li>
                                                    {
                                                        props.sportNavigations["edges"].length !== 0 ? (
                                                            props.sportNavigations["edges"].slice(0, 3).map((sportItem) => <li key={sportItem.node.sport.slug}>
                                                                <a href={`/high-school/${sportItem.node.sport.slug}/scores-schedule`}>
                                                                    {sportItem.node.sport.name}
                                                                </a>
                                                            </li>)
                                                        ) : null
                                                    }
                                                </ul>
                                            </>
                                        ) : <p>We're sorry for the inconvenience! Please check back soon.</p>
                                    }
                                </div>
                            </div>
                        </>
                    </div>
                    <div className="col-12 col-lg-4">
                        <DfpAd
                            desktopSizes={[[300, 250]]}
                            mobileSizes={[]}
                            targeting={{
                                pos: "right",
                                environment: process.env.APP_ENV || 'prod',
                                site: "hs-sports"
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container p-0 m-0">
            { homePage && page === 1 ?
                <div className="row mb-0 mb-lg-4">
                    <div className="col-12 col-lg-8 p-0">
                        {
                            mainHero ? (
                                <div className={styles.heroStory} data-cy={'heroStory'}>
                                    <div className={styles.heroStoryImage}>
                                        <a href={mainHero.url}>
                                            <img src={get(mainHero, 'leadArtLarge')} alt={"Lead Image"} />
                                        </a>
                                    </div>

                                    <div className="parallelogram parallelogram-yellow">
                                        <a href={`https://deseret.com${get(mainHero, 'primarySection.path')}`}>{ get(mainHero, 'primarySection.name') }</a>
                                    </div>

                                    <a href={mainHero.url}>
                                        <h1>{ get(mainHero, 'title') }</h1>
                                    </a>
                                    {
                                        get(mainHero, 'description') ? <p className="d-none d-lg-block">{ get(mainHero, 'description') }</p> : <></>
                                    }
                                    <a href={mainHero.url}><span className={styles.heroAuthorByline}>{ makeByLine(get(mainHero, 'authors')) }</span></a>

                                </div>
                            ) : <></>
                        }
                    </div>
                    <div className="col-12 col-lg-4">
                        {
                            heroSides.map(hero => hero ? (
                                <div className={styles.heroSideStory} key={hero.uid} data-cy={'heroSideStory'}>
                                    <div className={styles.heroSideStoryImage} data-cy={'heroSideStoryImage'}>
                                        <a href={hero.url}>
                                            <img src={get(hero, 'leadArtLarge')} alt={"Lead Image"}/>
                                        </a>
                                    </div>
                                    <div className={styles.heroSideStoryDescription}>
                                        <div className="parallelogram parallelogram-yellow">
                                            <a href={`https://deseret.com${get(hero, 'primarySection.path')}`}>{ get(hero, 'primarySection.name') }</a>
                                        </div>

                                        <a href={hero.url}>
                                            <h2>{ get(hero, 'title') }</h2>
                                        </a>
                                        <a href={hero.url}><span className={styles.heroAuthorByline}>{makeByLine(get(hero, 'authors'))}</span></a>
                                    </div>
                                </div>
                            ) : <></>)
                        }
                    </div>
                </div> : <></>
            }

            <div className="row">
                <div className="col-12 col-lg-8">
                    {riverStories.length === 0 ?
                        <div className={styles.box}>
                            <p>Sorry, there are no recent stories. Check back again in the future.</p>
                        </div>
                        :
                        <>
                        {riverStories.map(story => {
                            const pubDate = moment(get(story, 'publishDate'));

                            return <div className={styles.riverStory} key={get(story, 'uid')} data-cy={'riverStory'}>
                                <div className={styles.riverStoryThumbnail}>
                                    <a href={story.url}>
                                        <img src={get(story, 'leadArtThumb')} alt={"Lead Image"} />
                                    </a>
                                </div>
                                <div className={styles.riverStoryDescription}>
                                    <a href={story.url}>
                                        <h3>{get(story, 'title')}</h3>
                                    </a>

                                    <div className={styles.riverStoryByline}>
                                        <a href={story.url} className={styles.riverStoryAuthor}>{makeByLine(get(story, 'authors'))}</a>
                                        <span>{pubDate.isSame(new Date(),'year') ? pubDate.format('MMMM D') : pubDate.format('MMMM D, YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        })}
                        <nav className={styles.navigation} data-cy="navQueue">
                            {/* Conditional logic to disable paginating behind 1 */}
                            <button type="button" disabled={loading || page === 1} onClick={() => pageSelected(page - 1)}>Prev</button>
                            {/* Conditional logic to prevent paginating beyong pages & showing the archives instead */}
                            <button type="button" disabled={loading} onClick={() => pageSelected(page + 1)}>Next</button>
                        </nav>
                        </>
                    }
                </div>
                <div className="col-12 col-lg-4">
                    <DfpAd
                        desktopSizes={[[300, 250]]}
                        mobileSizes={[]}
                        targeting={{
                            pos: "right",
                            environment: process.env.APP_ENV || 'prod',
                            site: "hs-sports"
                        }}
                    />
                    { homePage ?
                        <>
                            <PlayerOfTheWeekWidget {...props} />
                            <ScoreSubmissionWidget />
                        </>
                    : <></>
                    }
                </div>
            </div>
        </div>
    );
}
